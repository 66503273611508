var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "n-page",
    [
      _c(
        "a-row",
        [
          _c(
            "a-col",
            { attrs: { lg: 12, md: 24, sm: 24, xs: 24 } },
            [
              _vm._v("\n      " + _vm._s(_vm.clubLoadingStatus) + "\n      "),
              _c(
                "a-card",
                {
                  class: { "gx-card-full": _vm.clubLoadingStatus },
                  attrs: { title: "Club Settings" },
                },
                [
                  _c(
                    "a-form",
                    {
                      class: { "gx-hide": _vm.clubLoadingStatus },
                      attrs: { form: _vm.form, layout: "vertical" },
                    },
                    [
                      _vm.club.club_logo
                        ? [
                            _c("img", {
                              staticStyle: {
                                "max-height": "120px",
                                "max-width": "100%",
                              },
                              attrs: { src: _vm.clubLogo },
                            }),
                          ]
                        : _vm._e(),
                      _c("p", { staticClass: "gx-mt-3" }, [
                        _vm._v(
                          "\n            We suggest using a 400x400px png or jpeg image for your logo\n          "
                        ),
                      ]),
                      _c(
                        "a-form-item",
                        [
                          _c(
                            "a-upload",
                            {
                              directives: [
                                {
                                  name: "decorator",
                                  rawName: "v-decorator",
                                  value: ["upload"],
                                  expression: "['upload']",
                                },
                              ],
                              attrs: {
                                accept: "image/png,image/jpeg/image/jpg",
                                name: "logo",
                                action:
                                  "https://api.subsapp.com/api/club/upload-club-logo/",
                                headers: { Authorization: _vm.userToken },
                                "list-type": "picture",
                                "show-upload-list": false,
                              },
                              on: { change: _vm.uploadImage },
                            },
                            [
                              _c(
                                "a-button",
                                [
                                  _c("a-icon", { attrs: { type: "upload" } }),
                                  _vm._v(" Change your Logo "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "Club Name" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "club_name",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message:
                                          "Please enter the name of your club.",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                'club_name',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Please enter the name of your club.',\n                    },\n                  ],\n                },\n              ]",
                              },
                            ],
                            attrs: { type: "text" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "Club Email" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: [
                                  "work_email",
                                  {
                                    rules: [
                                      {
                                        required: true,
                                        message: "Club email is required.",
                                      },
                                    ],
                                  },
                                ],
                                expression:
                                  "[\n                'work_email',\n                {\n                  rules: [\n                    {\n                      required: true,\n                      message: 'Club email is required.',\n                    },\n                  ],\n                },\n              ]",
                              },
                            ],
                            attrs: { type: "text" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "Contact Number" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["contact_phone"],
                                expression: "['contact_phone']",
                              },
                            ],
                            attrs: { type: "text" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "Main Address" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["address"],
                                expression: "['address']",
                              },
                            ],
                            attrs: { type: "textarea" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "Facebook URL" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["facebook"],
                                expression: "['facebook']",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "https://facebook.com",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "Twitter URL" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["twitter"],
                                expression: "['twitter']",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "https://twitter.com",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { attrs: { label: "Website URL" } },
                        [
                          _c("a-input", {
                            directives: [
                              {
                                name: "decorator",
                                rawName: "v-decorator",
                                value: ["website"],
                                expression: "['website']",
                              },
                            ],
                            attrs: {
                              type: "text",
                              placeholder: "https://mywebsite.com",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "a-form-item",
                        { staticClass: "gx-text-right" },
                        [
                          _c(
                            "a-button",
                            {
                              attrs: { type: "primary", "html-type": "submit" },
                              on: { click: _vm.handleForm },
                            },
                            [
                              _vm._v(
                                "\n              Save Details\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm.clubLoadingStatus
                    ? _c("n-section-loading", {
                        attrs: { title: "Loading club details..." },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }